.image {
  display: block;
  margin: 15px auto 30px;
}

.next {
  text-align: center;
  margin-bottom: 54px;
  margin-top: 30px;

  h5 {
    font-size: 18px;
    font-weight: bold;
    color: #31364c;
  }

  div {
    font-size: 14px;
    line-height: 1.64;
    letter-spacing: -0.3px;
    color: #787e9c;
  }

  p {
    font-size: 14px;
    text-align: left;
  }

  .innerText {
    text-align: left;

    span {
      cursor: pointer;

      b {
        color: #00c3cc;
      }
    }
  }
}

.message {
  font-size: 14px;
  line-height: 1.64;
  letter-spacing: -0.3px;
  text-align: center;
  color: #787e9c;
  padding: 15px;
}

.box{
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  border: solid 1.3px #ffffff;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.support {
  @extend .box;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 26px;
  span {
    cursor: pointer;
    b {
      color: #00c3cc;
    }
  }
}

.autoPaymentsMessage {
  @extend .box;
  color: #00c3cc !important;
  text-transform: uppercase;
  padding-top: 23px;
  padding-bottom: 23px;
}