.title {
  text-align: left;
  margin: 15px;
}

.content {
  margin: 15px;

  p {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.57;
    letter-spacing: -0.34px;
    text-align: left;
    color: #787e9c;
    margin-bottom: 24px;

    span {
      color: #07c0ca;
      cursor: pointer;
    }
  }
}

.message {
  letter-spacing: normal;
}