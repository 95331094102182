.title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.3px;
  text-align: center;
  color: #31364c;
  margin-bottom: 18px;
  text-transform: uppercase;
}

.smallFont {
  font-size: 10px;
}

.close {
  font-size: 32px;
}

.updated {
  font-weight: bold;
  font-size: 10px;
  color: #6F7595;
}

.modal {
  background-color: rgba(49, 54, 76, 0.65);
}

.modalDialog {
  border-radius: 8px !important;
  box-shadow: 1px 1px 15px 0 rgba(120, 126, 156, 0.2);
  background-color: #ffffff;
  margin: 30px auto !important;
  max-width: 325px !important;

  .modalContent {
    border-radius: 8px !important;

    .modalBody {
      overflow: scroll;
      max-width: 100%;
      padding-bottom: 40px;
      padding-top: 8px;
      max-height: calc(93vh - 60px);

      h4 {
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.27px;
        text-align: center;
        color: #31364c;
      }

      .paraTitle {
        font-size: 12px;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: normal;
        color: #31364c;
        margin-bottom: 8px;
        text-align: left;
        text-transform: uppercase;
      }

      .center {
        text-align: center;
      }

      .text {
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #6F7595;
        margin-bottom: 15px;
        // word-break: break-all;
      }

      .table {
        font-size: 12px;
        color: #6F7595;
        font-weight: normal;
        line-height: 1.5;
        margin-bottom: 24px;
        width: 280px;

        .tableHeader {
          border-right: 1px solid #00c3cc;

          th {
            background-color: #00c3cc;
            color: #ffffff;
            text-transform: uppercase;
            padding: 8px;
            max-width: 60px;
            font-size: 11px;
            border-right: 1px solid #ffffff;

            &:first-child {
              padding-left: 16px;
            }

            &:last-child {
              // border-bottom: 1px solid #ffffff;
              padding-right: 16px;
              border-right: 1px solid #00c3cc;
            }

            &.middleCell {
              padding: 4px;
              font-size: 11px;
            }
          }
        }

        .tableBody {
          tr {
            border-bottom: 1px solid #00c3cc;

            td {
              padding: 16px;
              min-width: 70px;
              border-left: 1px solid #00c3cc;

              &:first-child {
                // background-color: #00c3cc;
                // color: #ffffff;
                // text-transform: uppercase;
                // border-bottom: 1px solid #ffffff;
                // font-weight: 600;
              }

              &:last-child {
                border-right: 1px solid #00c3cc;
              }
            }
          }
        }
      }

      ol,
      ul {
        padding-left: 24px;

        li {
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.5;
          letter-spacing: normal;
          color: #6F7595;
          padding-left: 12px;
          margin-bottom: 4px;
          p {
            font-size: 12px;
            color: #6F7595;
          }
        }
      }
    }
  }
}

.orderedLowerAlphaList {
  ol {
    counter-reset: list;
  }
  ol > li {
    list-style: none;
    position: relative;
  }
  ol > li:before {
    content: "(" counter(list, lower-alpha) ") ";
    counter-increment: list;
    position: absolute;
    left: -1.4em;    
  }  
}

.orderedLowerRomanList {
  ol {
    counter-reset: list;
  }
  ol > li {
    list-style: none;
    position: relative;
  }
  ol > li:before {
    content: "(" counter(list, lower-roman) ") ";
    counter-increment: list;
    position: absolute;
    left: -1.4em;    
  }  
}

.wrapAll {
  word-break: break-all;
}